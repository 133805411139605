.appMainContainer {
  background: url("../assets/backgroundImage.svg");
}
.headerContainer {
  padding: 50px 60px;
  display: flex;
  justify-content: space-between;
}
.headerContainerMob {
  padding: 40px 25px;
}
.logoContainer {
  display: flex;
}
.raritiLogo {
  width: 43px;
}
.raritiLogoMob {
  width: 25px;
  height: 43px;
}
.raritiTitle {
  padding-left: 9px;
  color: #fff;
  font-family: "saira";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.raritiTitleMob {
  font-size: 30px;
}
.getInTouchButton {
  padding: 15px 20px;
  border-radius: 15px;
  border: 1px solid rgba(245, 251, 242, 0.44);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) -30.29%,
    rgba(255, 255, 255, 0) 144.92%
  );
  backdrop-filter: blur(25px);
  color: var(--white, #fff);
  font-family: "michroma";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  cursor: pointer;
  margin-top: 8px;
}
.getInTouchButtonMob {
  font-size: 11px;
  line-height: 20px;
  padding: 8px 12px;
  margin-top: 3px;
}
.arrowIcon {
  margin-left: 19px;
  width: 21px;
}
.arrowIconMob {
  margin-left: 10px;
  width: 15px;
}
.bodyContainer {
  margin-top: 165px;
  margin-left: 162px;
  @media screen and (max-width: 1400px) {
    margin-left: 160px;
  }
  @media screen and (max-width: 1350px) {
    margin-left: 140px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: 120px;
  }
  @media screen and (max-width: 1290px) {
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
}
.bodyContainerMob {
  margin-top: 110px;
  margin-left: 0px;
  margin-right: 0px;
  text-align: center;
}
.slashSymbol {
  color: #02aab0;
  font-family: "Michroma";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding-right: 8px;
}
.slashSymbolMob {
  font-size: 20px;
  color: #02aab0;
  font-family: "Michroma";
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  padding-right: 8px;
}
.firstContent,
.secondContent,
.thirdContent {
  color: var(--white, #fff);
  font-family: "Michroma";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
}
.firstContentMob {
  color: var(--white, #fff);
  font-family: "Michroma";
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 25px;
}

.firstContent::after {
  content: "\a";
  white-space: pre;
}
.secondContent::after {
  content: "\a";
  white-space: pre;
}
.projectContainer {
  display: flex;
  justify-content: center;
  margin-top: 290px;
}
.projectContainerMob {
  margin-top: 130px;
}
.ourProjects {
  color: var(--white, #fff);
  font-family: "Michroma";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ourProjectsMob {
  font-size: 18px;
  line-height: 143%;
}
.projectbuttonsContainer {
  margin-top: 54px;
  display: flex;
  margin-left: 183px;
  margin-right: 183px;
  justify-content: space-between;
  margin-bottom: 293px;
  @media screen and (max-width: 1400px) {
    margin-left: 160px;
    margin-right: 160px;
  }
  @media screen and (max-width: 1350px) {
    margin-left: 140px;
    margin-right: 140px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: 120px;
    margin-right: 120px;
  }
  @media screen and (max-width: 1250px) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media screen and (max-width: 1200px) {
    margin-left: 80px;
    margin-right: 80px;
  }
  @media screen and (max-width: 1150px) {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media screen and (max-width: 1000px) {
    margin-left: 40px;
    margin-right: 40px;
  }
}
.projectbuttonsContainerMob {
  flex-direction: column;
  gap: 25px;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 50px;
  margin-top: 30px;
}
.radeButton {
  display: flex;
  border-radius: 15px;
  border: 1px solid rgba(245, 251, 242, 0.44);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) -30.29%,
    rgba(255, 255, 255, 0) 144.92%
  );
  backdrop-filter: blur(25px);
  padding: 61px 60px;
  cursor: pointer;
  @media screen and (max-width: 1150px) {
    padding: 61px 40px;
  }
}
.radeButtonMob {
  padding: 25px 60px;
  justify-content: center;
}
.radeIcon {
  width: 45px;
  height: 65px;
  transform: rotate(0.37deg);
  margin-right: 15px;
}
.radeIconMob {
  width: 38px;
  height: 47px;
  margin-right: 10px;
}
.radeTitle {
  color: #fff;
  font-family: "Exo";
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.radeTitleMob {
  font-size: 38px;
}
.payezyButton {
  display: flex;
  border-radius: 15px;
  border: 1px solid rgba(245, 251, 242, 0.44);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) -30.29%,
    rgba(255, 255, 255, 0) 144.92%
  );
  backdrop-filter: blur(25px);
  padding: 72px 36px;
  cursor: pointer;
  @media screen and (max-width: 1150px) {
    padding: 72px 30px;
  }
}
.payezyButtonMob {
  padding: 35px 36px;
  justify-content: center;
}
.payezyIcon {
  width: 247px;
  height: 42px;
}
.payezyIconMob {
  width: 200px;
  height: 30px;
}
.tetherxButton {
  display: flex;
  border-radius: 15px;
  border: 1px solid rgba(245, 251, 242, 0.44);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) -30.29%,
    rgba(255, 255, 255, 0) 144.92%
  );
  backdrop-filter: blur(25px);
  padding: 72px 43px 66px 42px;
  cursor: pointer;
  @media screen and (max-width: 1150px) {
    padding: 72px 30px 66px 30px;
  }
}
.tetherxButtonMob {
  justify-content: center;
  padding: 35px 43px 35px 42px;
}
.tethexTitle {
  color: #fff;
  font-family: "Zen Dots";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tethexTitleMob {
  font-size: 28px;
}
.tetherxIcon {
  width: 69px;
  height: 39px;
}
.tetherxIconMob {
  width: 50px;
  height: 32px;
}
.getInTouchButton:hover,
.payezyButton:hover,
.tetherxButton:hover,
.radeButton:hover {
  box-shadow: 0 0 10px 0 #02aab0;
  border: 1px solid #02aab0;
}
.footerContainer {
  text-align: center;
  padding-bottom: 15px;
}
.footerSlash {
  font-family: "Michroma";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #02aab0;
}
.footerContent {
  color: var(--neutral-0, #fff);
  font-family: "Saira";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
}
.hyperlink {
  text-decoration: none;
}
.popupOverlay {
  position: fixed;
  inset: 0px;
  background: rgba(196, 196, 196, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popupContent {
  background: #081527;
  border: 2px solid var(--Green-grad, #02aab0);
  width: 25%;
  border-radius: 10px;
  height: auto;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 14px;
    margin-right: 14px;
  }
}
